import { useCallback } from "react";
import { Link } from "../components/Link";
import { SUPPORT_EMAIL } from "../consts/emails";
import { AnalyticsContextValue, useAnalyticsContext } from "../context/AnalyticsContext";
import { useNotifications } from "./useNotifications";

export type UseOpenIntercomReturnType = {
  /**
   * Opens Intercom with an optional message.
   * @param message The message to open Intercom with.
   */
  openIntercom(message?: string): void;
  /**
   * Opens Intercom.  Takes no arguments so it can be used as a static callback anywhere.
   */
  openIntercomCallback(): void;
  /**
   * The analytics object from which Intercom comes.
   */
  analytics: AnalyticsContextValue;
  /**
   * true if Intercom is available.
   */
  hasIntercom: boolean;
};

export const useOpenIntercom = (defaultMessage?: string): UseOpenIntercomReturnType => {
  const analytics = useAnalyticsContext();
  const hasIntercom = !!analytics.state.intercom?.booted;

  const { sendNotification } = useNotifications();

  const openIntercom = useCallback<UseOpenIntercomReturnType["openIntercom"]>(() => {
    if (!hasIntercom)
      sendNotification({
        message: (
          <>
            Couldn't load Intercom. Try sending us an email at{" "}
            <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>. Want to know more? Check out our{" "}
            <Link href="https://help.reclaim.ai/en/articles/6338330">help docs</Link>.
          </>
        ),
        type: "warning",
      });
    else analytics.state.intercom?.("show");
  }, [analytics.state, hasIntercom, sendNotification]);

  const openIntercomCallback = useCallback(() => openIntercom(), [openIntercom]);

  return { openIntercom, openIntercomCallback, analytics, hasIntercom };
};
